/* index.css */


@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

.inputs_Configuration {
    max-width: 450px;
    margin: 30px auto;
    padding: 25px;
    background: white;
    box-shadow: 0px 0px 10px #ebecff;
    border-radius: 2px;
    position: relative;
}

.Configuration-section img {
    margin: 0 auto;
    text-align: center;
    display: block;
}

.autosize {
    width: 100%;
    resize: none;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 6px !important;
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.40);

}

.errorColor {
    border-color: red !important;
}

.textarea-no-scrollbar {
    padding: 8px 12px !important;
    color: #616161;
    margin-bottom: 8px;
    max-height: 94px;
}

#root .bouncing-loader {
    z-index: 999 !important;
    height: 100vh !important;
}

.inputs_Configuration h2 {
    text-align: center;
    margin: 0px 0px 20px;
    font-size: 24px;
    font-size: 20px;
    font-weight: 400;
    color: #fe4808;
}
.bouncing-loader div {
    margin-top: 200px;
}

/* .Configuration-section {
    margin-top: 10vh;
} */

.Configuration-section-popup {
    width: 40%;
    margin-left: 30%;
    box-shadow: 0px 0px 20px #ebecff;
    border-radius: 10px;

}

.close-modal {
    display: flex;
    justify-content: end;
}

.close__ {
    cursor: pointer;
}

.customer-card .close-modal {
    display: flex;
    justify-content: end;
   height: 24px;
}

.customer-card {
    width: 65% !important;
    position: absolute;
    background: white;
    left: 0%;
    right: 0px;
    margin: auto;
    top: 9%;
    padding: 20px;
    border-radius: 5px;
}

.create-connection {
    display: flex;
    width: 75%;
}

.row-heading {
    padding-top: 20px;
    text-align: center;
    opacity: 0.8;
    font-family: 'Roboto', sans-serif;
}

.connectionBackBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    border: navajowhite;
    background: #1F0437;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;

}

table#invoiceTable tbody {
    position: relative;
    height: 31px;
}

.method {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}

.Configuration-section-popup button {
    display: flex;
    align-items: center;
    gap: 5px;
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
}

.inputs_Configuration a {
    text-decoration: none;
    position: absolute;
    right: 25px;
    font-size: 12px;
    color: #666666;
    margin-top: 10px;
}

.inputs_Configuration i.bi.bi-info-circle-fill {
    font-size: 14px;
    width: 14px;
    color: #212523;
    margin-left: 5px;
}

.inputs_Configuration label.form-label {
    margin-top: 10px;
}

.inputs_Configuration p {
    font-size: 12px;

    color: #666666;
}

* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
    background-color: #ffffff !important;
}

.table-bordered>:not(caption)>*>* {
    border-width: 0px !important;
}

.hideDropDown {
    display: none;
    position: relative;
    border: none;
}

.First_drop_ul {
    display: block;
    position: relative;
    border: none;

}

.Second_drop_ul {
    display: block;
    position: relative;
    border: none;

}

.Product_drop_ul {
    display: none;
    position: relative;
    border: none;

}

.Gernal_drop_ul {
    display: block;
    position: relative;
    border: none;

}

.API_drop_ul {
    display: block;
    position: relative;
    border: none;

}

.arrowStraight {
    justify-content: end;
    display: flex;
    width: auto;
    margin-left: auto;
}

.arrowDown {
    justify-content: end;
    display: flex;
    width: auto;
    margin-left: auto;
    transform: rotate(-270deg) !important;

}

.main-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.searchLabel {
    display: inline-block;
    margin-right: 10px;
}


div#example_filter label {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

div#example_filter {
    justify-content: end;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

div#example_length label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666666;
    font-size: 14px;
}

.active {
    color: #fe4808
}

.side_links {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: start;
    width: 100%;
}

.imageLogo {
    width: 200px;
    margin-top: 100px;
}

.left_side_tabs {
    background: white;
    box-shadow: 0px 0px 10px #ebecff;
    padding: 20px 25px;
    border-radius: 2px;
    width: 98%;
    height: 100%;
}

.right_side_content {
    padding: 25px;
    background: white;
    box-shadow: 0px 0px 10px #ebecff;
    border-radius: 2px;
}

li {
    list-style: none;
    margin: 0;
}

ul {
    padding: 0 !important;
    margin: 0 !important;
}

a.active {
    color: #fe4808 !important;
}

a.active span {
    color: #fe4808 !important;
}

ul.pagination li a {
    font-size: 14px !important;
}

/* .side_links li span {
    color: #dddddd;
} */

.left_side_tabs .side_links a:hover {
    color: #fe4808 !important;
}

.form-control:focus {
    box-shadow: 0 0 0 1px rgb(254 72 8) !important;
    /* background-color: white !important; */
}

.Setting {
    text-decoration: none;
}

.AddNewElement {
    text-decoration: none;
}

.side_links a {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    width: 100%;
    gap: 5px;
    text-decoration: none;
    margin: 10px 0px;
    font-size: 14px;
    color: #616161;
}

.spanEntriesstart {
    opacity: 0.7;
    margin-right: 5px;
}

.spanEntriesend {
    opacity: 0.7;
    margin-left: 5px;
}

.searchfilter {
    display: flex;
    width: 100%;
    align-items: baseline;
    text-align: end;
    justify-content: end;
}

.searchfilter2 {
    display: flex;
    width: 100%;
    align-items: start;
    text-align: end;
    justify-content: end;
}

.productformat {
    background-color: whitesmoke;
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 10px;
}

.addProduct {
    cursor: pointer;
}

.productRow {
    width: 100%;
}

.AddDeleteIcon {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.PRODUCT_PAGE_SECTION {
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px;
}

button.filter {
    display: flex;
    align-items: center;
    gap: 5px;
    border: navajowhite;
    background: #0088cc;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
}


.main-title h2 {
    font-size: 20px;
    font-weight: 400;
    color: #fe4808;
}

button.setting_btn_head {
    height: 40px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C9CCCF;
    BACKGROUND: WHITE;
    BORDER-RADIUS: 5PX;
    COLOR: #616161;

}

.top_header_btns {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
    padding-bottom: 20px;
}

.main-title {
    margin-bottom: 30px;
}


select.form-select.form-select-sm {
    max-width: 70px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {

    --bs-table-bg-type: rgb(255 255 255 / 5%) !important;
}

table#example thead tr th {
    background: #ffffff24 !important;
    font-size: 12px;
    color: #757575;
    font-weight: bold;
    padding: 14px 10px;
}

table#invoiceTable thead tr th {
    background: #ffffff24 !important;
    font-size: 12px;
    color: #757575;
    font-weight: bold;
    padding: 14px 10px;
}

div#example_info {
    font-size: 14px;
    color: #616161;
}

.table-bordered>:not(caption)>* {
    border-top: 0 !important;
}


.row.dt-row {
    margin-top: 10px;
    max-width: 1600px !important;
    overflow: auto !important;
}

.row.dt-row td {
    font-size: 14px;
    color: #616161 !important;
    padding: 14px 10px !important;
    font-weight: 400;
}

button.filter span {
    font-size: 18px;
}

ul.pagination {
    justify-content: end;
}

.active>.page-link,
.page-link.active {
    background-color: #fe4808 !important;
    border-color: #fe4808 !important;
    color: white !important;
}

.page-link {
    color: #1b213a !important;
}

.side_links p {
    margin: 0;
    padding: 10px;
    font-size: 14px;
    text-align: start;
    font-weight: 600;
    color: #666666;
}

td.action span#trash {
    background: #cb183e;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #80808045;
    color: white;
    cursor: pointer;
}

span#eye {
    background: #1b213a;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #80808045;
    color: white;
    cursor: pointer;
}


td.action span#edit {
    background: #e2ad0c;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #80808045;
    color: #1b213a;
    cursor: pointer;
}

th.Action.sorting {
    text-align: center;
}

td.action {
    text-align: center;
}

/* ==========================================================   add_product_popup    ================================================================ */

.add_product_popup label {
    font-size: 14px;
    color: #212224;
    margin-bottom: 5px;
}


.add_product_popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000c7;
    padding: 10px;
    display: none;
    z-index: 9;
}

.inner_popup {
    max-width: 750px;
    background: white;
    border-radius: 5px;
    margin: 1% auto;
    max-height: 100vh;
    overflow: auto;
}

.popup_header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
}

.popup_header h4 {
    font-size: 20px;
    font-weight: 600;
    color: #212224;
    margin: 0;
}

.popup_body {
    padding: 20px;
}

.Product_cost {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    flex-direction: row !important;
}

.Product_cost div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.popup_body div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

input[type="text"],
select {
    font-size: 14px;
    border: 1px solid #d6d9dd !important;
    border-radius: 5px;
    height: 40px !important;
    padding: 10px !important;
}

input[type="date"],
select {
    font-size: 14px;
    border: 1px solid #d6d9dd !important;
    border-radius: 5px;
    height: 40px !important;
    padding: 10px !important;
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.40);
}

.Product_cost input[type="text"]:focus {
    box-shadow: 0 0 0 1px rgb(254 72 8) !important;

}

.Product_cost {
    margin-bottom: 0px !important;
}


.Product_cost div {
    margin: 0px !important;
}

.radio_have {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.radio_have p {
    margin: 0;
    font-size: 14px;
}


.editer_sec {
    padding: 20px;
}

.inner_popup label span {
    color: red;
}

.tox.tox-tinymce {
    max-height: 320px !important;
}

.first_one_time select#Product {
    max-width: 350px;
    font-size: 14px;
}

.popup_header span {
    cursor: pointer;
}

.action {
    text-align: center;
}

.cancel-sub {
    text-align: center;
}

.alert.alert-success.copiedData {
    padding: 7px;
    z-index: 5;
    position: absolute;
    left: 30%;
}

.backBtn {
    margin-left: -50px;
}

button#Save_changes {
    margin-left: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    align-items: center;
    display: flex;
    gap: 5px;
}

button#Save_changes span {
    font-size: 20px;
}




/* =============================================  ALL PAYMENT FORMS LIST  ========================================== */


.first_circle_td {
    display: flex;
    align-items: start;
    gap: 5px;
    text-align: center;
}

table#example tr {
    vertical-align: text-top;
}

/* =============================================  PURCHASE LIST ========================================== */

select.form-select {
    font-size: 14px;
    height: 40px !important;
}

.form-control:focus {
    border-color: transparent !important;
}


.form-select:focus {
    box-shadow: 0 0 0 1px rgb(254 72 8) !important;
    border-color: transparent !important;
}
.row.productRow input.form-control {
    min-width: 90px;
}
.form-control {
    height: 40px !important;
    margin-bottom: 10px;
    padding: 5px 10px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.input_top_bar .Product_cost {
    gap: 50px;

}

.input_top_bar {
    margin-bottom: 30px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 30px;
}

div#PURCHASE_TABLE div#example_wrapper .row:first-child {
    display: flex;
    flex-direction: row-reverse;
}

div#PURCHASE_TABLE div#example_length label {
    justify-content: end;
}

div#PURCHASE_TABLE div#example_filter {
    display: none;
}

.inner_PDF_btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.all_btns {
    position: relative;
    margin-bottom: 20px;
}

.inner_PDF_btns button {
    padding: 8px 20px;
    background: white;
    border-radius: 3px;
    border: 1px solid #fe4808;
    font-size: 14px;
    color: #fe4808;
    font-weight: 500;
    transition: all ease-in-out 0.5;
}

.inner_PDF_btns button:hover {
    background: #fe4808;
    color: white;
    border: 2px solid #fe4808;
}

.paid p {
    margin: 0;
    background: #0499040c;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    color: #00a500;
    padding: 4px 10px;
    border: 1px solid;
    
}

.ReactModal__Content.ReactModal__Content--after-open.custom-modal {
    background: #c3c3c39e;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    backdrop-filter: blur(4px);
}


.action button.btn.btn-secondary.dropdown-toggle {
    font-size: 14px;
    background: #fe4808;
    border: none;
}

.Cancelled {
    width: 70%;
}

.Sent {
    width: 70%;
}

.Saved {
    width: 70%;
}

.cancel-sub button {
    background: #cb193b;
    color: white;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
}

.tox.tox-silver-sink.tox-tinymce-aux {
    overflow: hidden;
}


.Company_setting_inner .right_side_content {

    width: 100%;

}


.Company_setting_inner {
    display: flex;
    width: 100%;
    gap: 30px;
}


.Left_Bar_company {
    width: 20%;
}

.Left_Bar_company ul {
    width: 100%;
}

.Left_Bar_company .side_links li {
    width: 100%;
    background: rgb(255, 255, 255);
    border: 1px solid #fe4808;
    margin-bottom: 5px;
    border-radius: 5px 5px 0px 0px;

}

.Left_Bar_company .side_links a {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    gap: 5px;
    text-decoration: none;
    font-size: 14px;
    color: #fe4808;
    margin: 0;
    height: 50px;
}

.Left_Bar_company .side_links li span {
    color: #fe4808;
}



.Left_Bar_company a.active {
    color: #ffffff !important;
    background: #fe4808;
}

.Left_Bar_company a.active span {
    color: #ffffff !important;
}

.Left_Bar_company .d-flex {
    display: flex !important;
    gap: 20px !important;
}

.input_form.d-flex {
    display: flex;
    gap: 20px;
    text-align: start;
}

.input_form.d-flex textarea {
    font-size: 14px;
    border: 1px solid #d6d9dd !important;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    min-width: 150px;
}

.xampleInput {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
}

label {
    font-size: 14px;
}

ul.Second_drop_ul li {
    margin-left: 25px;
}

ul.First_drop_ul li {
    margin-left: 25px;
}


.main-title-SETTINGS h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #fe4808;

}

a#active {
    color: #0d6efd !important;
}

label {
    margin-bottom: 5px;
}

.Cancelled p {
    background: #ff000009 !important;
    color: red;
}

.Saved p {
    background: #6c757d17 !important;
    color: #6c757d;
}

.Sent p {
    background: #17a3b810 !important;
    color: #17a2b8;
}


.action ul.dropdown-menu.Invoices.involov_ul li a {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

span#add_circle {
    cursor: pointer;
}


/* ===========================================   Create_Invoice   ======================================================== */

.Create_Invoice_content h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.Create_Invoice_content .mb-3 {
    width: 100%;
}

.d-flex {
    display: flex;
    gap: 20px;
}


.invoiceSubmit {
    display: inline-block;
    justify-content: end;
    margin-left: auto;
    margin-top: 25px;
    padding: 10px 30px;
    font-size: 14px;
    background: #fe4808;
    border: none;
    color: white;
    border-radius: 5px;
}

.inputs_row {
    display: flex;
    gap: 10px;
}


.invoiceSubmit1 {
    display: inline-block;
    justify-content: end;
    margin-left: auto;
    margin-top: 25px;
    padding: 10px 30px;
    font-size: 14px;
    background: #008858;
    border: none;
    color: white;
    border-radius: 5px;
}

input.form-control.form-control-sm.paymentDateFilter {
    width: 3%;
    margin-left: 10px;
}

.main-title a {
    text-decoration: none;
}

.Invoice.Footer label {
    margin-bottom: 10px;
}

input#file-upload-button {
    opacity: 0;
}

input#upload {
    width: 100%;
    height: 70px;
    opacity: 0;
}

button.view-button:hover {
    box-shadow: 4px 4px 7px rgb(207, 205, 205) !important;
}

button.view-button {
    background: none;
    border: 1px solid black;
    padding: 5px 10px;
    opacity: 0.8;
    border-radius: 5px;
    margin-right: 10px;
}

button.edit-button:hover {
    box-shadow: 4px 4px 7px rgb(207, 205, 205) !important;
}

button.edit-button {
    background: none;
    border: 1px solid black;
    padding: 5px 10px;
    opacity: 0.8;
    border-radius: 5px;
    margin-right: 10px;
}

.logo_div {
    width: 100%;
    max-height: 250px;
    border: 1px solid #dddddd;
}


.Add_FILE_UPLOAD label {
    display: flex;
    background-color: #fe4808;
    color: white;
    padding: 0.5rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    max-width: 200px;
    font-size: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0px;
}

form.Add_FILE_UPLOAD {
    text-align: center;
}

span#close_top_btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

span#menu_btn {
    font-size: 32px;
    padding: 10px 15px;
    display: none;
    background: white;
    box-shadow: 0px 2px 2px #8080802b;
    cursor: pointer;
}

/* ==============================  Sure_popup  ========================== */

.Sure_popup_body {
    background: white;
    max-width: 550px;
    text-align: center;
    margin: 25vh auto;
    border-radius: 10px;
    padding: 30px 20px;
}

.Sure_popup {
    position: fixed;
    top: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #0000008a;
    display: none;
}

.Sure_popup_body h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.Sure_popup_body p {
    font-size: 14px;
    line-height: 20px;
}

.btn_div button {
    font-size: 14px;
    border: none;
    padding: 8px 20px;
    border-radius: 3px;
    color: white;
}

.serachLabel_payment {
    opacity: 0.5;
    margin-top: 7px;
    position: absolute;
    left: 46%;
}

.order_payment {
    padding: 0 0 0 27px !important;
}

.cancelModal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    width: 100% !important;
    max-width: 450px;
    left: 0% !important;
    top: 45px !important;
}

svg.No_svg path {
    color: #fe4808;
    fill: #fe4808 !important;
}

.taxable_ {
    opacity: 0.4;
}

.col-11.modal_head_heading {
    text-align: center;
    font-size: x-large;
}

.modal_head_close {
    cursor: pointer;
}


input.form-control.form-control-sm.search_invoices {
    margin-top: 11px;
    width: 33%;
}

.submit_invoice_section {
    text-align: end;
}

button.merchant {
    width: 30%;
    /* margin-top: 75px; */
    display: flex;
    align-items: center;
    /* gap: 5px; */
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    justify-content: center;
}

button.gateway {
    width: 30%;
    /* margin-top: 75px; */
    display: flex;
    align-items: center;
    /* gap: 5px; */
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    justify-content: center;
}

.Sure_popup_body span.material-symbols-outlined {
    font-size: 120px;
    border: 10px solid #ff6b6b;
    border-radius: 100px;
    font-weight: 400;
    color: #ff6b6b;
    margin-bottom: 30px;
}

button#pause {
    background-color: #0d6efd;
}

button#Cancel_btn {
    background-color: #ff6b6b;
    transition: all ease-in-out 0.2s;
}

button#Cancel_btn:hover {
    background-color: red;
}

th.sorting {
    min-width: 115px;
}

/* ============================================  Virtul_terminal  =================================================  */

.Virtul_one .d-flex {
    flex-direction: column;
}

.Description_div {
    display: flex;
    flex-direction: column;
}

.Description_div textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #d6d9dd !important;
    border-radius: 5px;
}

.SelectBox {
    word-wrap: normal !important;
    width: 56px !important;
    padding: 10px !important;
}

.showDialog {
    margin: 2px 0 0 28px !important;
}

input[type="text"],
select {
    font-size: 14px;
    /* background-color: #ffffff !important; */
    border: none !important;
    border: 1px solid #d6d9dd !important;
    padding: 10px !important;
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.40);
}

/* textarea[type="text"], select {
  font-size: 14px;
  border: 1px solid #d6d9dd !important;
  border-radius: 5px;
  height: 40px !important;
  padding: 10px !important;
} */

.card_types img {
    width: 40px;
    margin: 0;
}

.card_types {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.bank_cards {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    text-align: center;
    max-width: 100%;
    margin: 0 auto 20px;
    align-items: center;
}

.bank_cards .active {
    width: 50%;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 12px -8px rgba(0, 0, 0, 0.40);
    cursor: pointer;
    color: #fe4808;
}

.Bank_Account {
    padding: 10px;
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
}

.Bank_Account p {
    margin-bottom: 5px;
    font-size: 14px;
}

.Debit-Credit p {
    margin-bottom: 5px;
    font-size: 14px;
}

button.sm.px-4.rounded-none.flex-grow.hyfin-k2tlbd {
    width: 100%;
    margin-top: 75px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    justify-content: center;
}

.d-flex.inner_virtul_div {
    gap: 0px !important;
}

.Create_Invoice_content.Virtul_two {
    margin-top: -20px;
}

ul.Product_drop_ul li {
    margin-left: 25px;
}

/* =========================================   invoice  draft_box  ========================================== */

.invoice_draft_box {
    box-shadow: 0px 0px 25px #8080802b;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.draft_section_invoice {
    margin-bottom: 20px;
}

.invoice_draft_box h2 {
    font-weight: 600;
    color: #616161;
    margin-top: 8px;
}

.Gateway {
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 30px; */
    align-items: center;
    gap: 20px;
}


.Gateway p {
    font-size: 14px;
    margin: 0;
}

/* ========================================= Create_product_section  ================================== */

.Create_product_section .inner_popup {
    max-width: 100%;
    background: white;
    border-radius: 5px;
    margin: 1% auto;
    max-height: fit-content;
    overflow: unset;
}

.Create_product_section .first_one_time select#Product {
    max-width: 100%;
    font-size: 14px;
}

.Create_product_section .popup_body {
    padding: 0;
    text-align: start;
}

.Create_product_section .editer_sec {
    padding: 20px 0px;
}

.Create_product_section .radio_have {
    padding: 20px 0px 0px;

}

.Export {
    display: flex;
    align-items: center;
    gap: 5px;
    border: navajowhite;
    background: #fe4808;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
}

td.green_check span {
    color: #00d700;
}

td.red_check span {
    color: #d70000;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    align-items: baseline !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-8nphli {
    align-items: baseline !important;
}

input.form-control.form-control-sm {
    background: #fafafa;
    width: 50%;
}

.card_td {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card_td p {
    margin: 0;
}

a.Second_drop_title span#Arrow_r {
    margin-left: 20px !important;
}

table#example {
    position: relative;
}


/* clender_section */
.clender_section {
    position: absolute;
    padding: 25px;
    background: white;
    box-shadow: 0px 0px 10px #ebecff;
    border-radius: 2px;
    z-index: 9999;
    top: 45px;
    max-width: 350px;
    display: none;
}

.days_section {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.days_section button {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #ddd;
    background: #ffffff;
    color: #757575;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0px 0px 10px #ebecff;
    transition: all ease-in-out 0.2s;

}

.inputs_div {
    display: flex;
    gap: 10px;
    padding-top: 10px;
}

.inputs_div input[type="date"] {
    border: 1px solid #ddd !important;
    color: #757575;

}

.clender_input {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.days_section button:hover {
    background: #fe4808;
    color: #fff;
    border: 1px solid #fe4808 !important;
}


.input_img_div {
    background: #ffffff;
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: 0px 0px 10px #ebecff;
    transition: all ease-in-out 0.2s;
}

.input_img_div:hover span {
    color: #fe4808;
}

.input_img_div:hover {
    border: 1px solid #fe4808;
    cursor: pointer;
}

.searchfilter.searchFilter2 {
    align-items: flex-start;
}



/* make connection dynamic */

.Create_Invoice_content .mb-3 label {
  margin-bottom: 5px;
  text-align: start;
  display: block;
}
.row.productRow label {
    display: block;
    text-align: start;
    margin-bottom: 5px !important;
}

.Product_cost label {
    text-align: start;
}
.col-1.AddDeleteIcon span {
    padding-top: 10px;
}

/* make connection dynamic */



img.inv_img {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    max-width: 150px;
    display: flex;
}



@media (max-width: 1200px) {

    .Create_Invoice_content.Virtul_two {
        margin-top: 0;
    }

    button.sm.px-4.rounded-none.flex-grow.hyfin-k2tlbd {
        margin-top: 0;
    }

    .left_side_tabs {
        width: 300px;
        height: 100%;
        margin-bottom: 20px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 9;
    }

    .Company_setting_inner .right_side_content {
        width: 100%;
    }

    .Left_Bar_company {
        width: 30%;
    }


    span#menu_btn {
        font-size: 32px;
        padding: 10px 15px;
        display: block;
        background: white;
        box-shadow: 0px 2px 2px #8080802b;
    }

    span#close_top_btn {
        display: block;


    }

    .left_side_tabs {
        display: none;
    }




    td {
        min-width: 200px;
    }

    .input_top_bar .Product_cost {
        gap: 20px;
        flex-wrap: wrap;
    }


}


@media (max-width: 767px) {

    .main-title {
        display: flex;
        flex-wrap: wrap;
    }

    .Gateway {
        flex-wrap: wrap;
        gap: 10px;
    }

    .Create_Invoice_content .d-flex {
        flex-wrap: wrap;
        gap: 0px;
    }

    .Product_cost {
        flex-wrap: wrap;
    }

    .Create_Invoice_content.Virtul_two {
        margin-top: 0;
    }

    .bank_cards {
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }

    button.sm.px-4.rounded-none.flex-grow.hyfin-k2tlbd {
        margin-top: 0;
    }

    .card_types {
        margin-top: 0;
    }

    .input_form.d-flex {
        display: flex;
        gap: 0px;
    }









    .all_btns {
        position: relative;
        margin-bottom: 10px;
    }

    .inner_PDF_btns {
        display: grid;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        grid-template-columns: 1fr 1fr;
    }

    div#PURCHASE_TABLE div#example_length label {
        justify-content: center;
    }

    .left_side_tabs {
        width: 250px;
        height: 100%;
        margin-bottom: 20px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 9;
    }

    .Left_Bar_company {
        width: 100%;
    }

    .Company_setting_inner .right_side_content {
        width: 100%;
    }

    .Company_setting_inner {
        flex-direction: column;
    }

    .input_form.d-flex {
        flex-wrap: wrap;
    }

    ul.pagination {
        justify-content: center;
    }

    div#example_info {
        text-align: center;
        margin-bottom: 5px;
    }

    .paid p {
        width: fit-content;
    }

    .left_side_tabs {
        padding: 20px;
    }
}

input.errorColor.form-control {
    border-color: red !important;
  }