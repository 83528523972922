/* App.css */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.brandLogo {
  padding: 16px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}


.conntection-section {
  background: white;
  border: 1px solid #ccc;
  max-width: 600px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 40px 30px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px #cccccc7d;
}

.conntection-section .imageLogo {
  width: 285px;

  margin-top: 0px !important;
}

.Create_Invoice_content .mb-3 .text-danger {
  position: absolute;
  font-size: 12px;
  bottom: -10px;
}

.Create_Invoice_content .mb-3 {
  position: relative;
}

.auth_Configuration {
  width: 100%;
  padding: 30px;
}

.auth_Configuration label.form-label {
  text-align: start;
  display: flex;
  justify-content: space-between;
}

.auth_Configuration h2 {
  margin-bottom: 30px;
  font-size: 24px;

}

.auth_Configuration label.form-label span {
  display: flex;
  gap: 5px;
}

p.info {
  text-align: start;
  font-size: 12px;
  color: #616161;
}

.auth_Configuration .create-connection {
  display: flex;
  width: 51%;
  justify-content: space-evenly;
  margin: auto;
}

.auth_Configuration label.form-label a {
  font-size: 12px;
  color: #0088cc;
}

.errorColor {
  border-color: red !important;
}


img.custom_menu_create_img {
  width: 1015px;
  position: relative;
}


.location-id-input {
  margin-bottom: 20px;
}

/* 07-08-2024 Css */
.instructions-section {
  max-width: 907px;
  width: calc(100% - 30px);
  margin-inline: auto;
  text-align: left;
  padding-block: 50px;
}

.instructions-section h1 {
  font-size: 38px;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 20px;
}

.instructions-section p {
  font-size: 16px;
  letter-spacing: 0;
  margin-bottom: 12px;
}

.instructions-section ol {
  padding: 0;
}

.instructions-section ol li {
  font-size: 15px;
  margin-bottom: 8px;
}

.instructions-section .custom_menu_create_img {
  margin-block: 25px;
  width: 100%;
  max-width: 700px;
  height: auto;
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.instructions-section .custom_menu_link_img {
  margin-block: 25px;
  width: 100%;
  max-width: 500px;
  height: auto;
  display: block;
  border: 1px solid #ddd;
  border-radius: 4px;
}


@media screen and (max-width: 991px) {
  .instructions-section h1 {
    font-size: 30px;
    margin-bottom: 17px;
  }
}

@media screen and (max-width: 767px) {
  .instructions-section h1 {
    font-size: 24px;
    margin-bottom: 17px;
  }

  .instructions-section p {
    font-size: 15px;
  }

  .instructions-section ol li {
    font-size: 14px;
    margin-bottom: 8px;
  }
}


.auth_Configuration {
  position: relative;
}
.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 8px 8px;
}